// This proxy is used to conditionally switch between the real and the mocked version
// of the "@auth0/auth0-vue" module at runtime, so that we can bypass and stub
// the authentication when running component tests.
import {
  Auth0VueClient,
  createAuth0 as createAuth0Original,
  useAuth0 as useAuth0Original,
} from '@auth0/auth0-vue'
import { Ref } from 'vue'

import { Auth0UserWithAudience } from '@/domain/auth0'
import {
  createAuth0 as createAuth0Mock,
  useAuth0 as useAuth0Mock,
} from '@/lib/auth0/auth0-mock'

interface ExtendedAuth0VueClient extends Auth0VueClient {
  user: Ref<Auth0UserWithAudience>
}

let createAuth0: typeof createAuth0Original
let useAuth0: () => ExtendedAuth0VueClient

// We set 'mock-id-token' and 'mock-access-token' in session storage when Playwright's page initialises
if (window.sessionStorage.getItem('mock-id-token')) {
  // We are casting the mocks so that we have proper TS types throughout the code
  createAuth0 = createAuth0Mock as unknown as typeof createAuth0Original
  useAuth0 = useAuth0Mock as unknown as () => ExtendedAuth0VueClient
} else {
  createAuth0 = createAuth0Original
  useAuth0 = useAuth0Original as unknown as () => ExtendedAuth0VueClient
}

export { createAuth0, useAuth0 }
