import { until } from '@vueuse/core'
import { Ref } from 'vue'
import { RouteLocationRaw, RouteLocationNormalized } from 'vue-router'

import { useAuth0 } from '@/composables/useAuth0'
import { useError } from '@/composables/useError'

export default async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: (arg0: RouteLocationRaw | void | boolean) => void
): Promise<RouteLocationRaw | void | boolean> => {
  const auth0 = useAuth0()
  auth0.requiresAuth.value = true

  await until(auth0.auth0Loading as Ref<boolean>).toBe(false)

  const authRole = to.meta?.role as string[]
  const role = auth0.getUserRole()
  const { silentError } = useError()

  if (!auth0.isAuthenticated.value || auth0.isExpired()) {
    // Redirect to login page
    await auth0.loginWithRedirect({
      appState: { target: to.fullPath },
    })
    return next()
  }

  // User is logged in, we now need to decide whether they are allowed to see what they are trying to see.
  if (
    import.meta.env.VITE_SITE_UNDER_MAINTENANCE === 'true' &&
    (role === 'PracticeManager' || !role)
  ) {
    return next({
      path: '/under-maintenance',
    })
  }

  if (_from.path === to.path) {
    return next()
  }

  if (authRole && !authRole.includes(role)) {
    silentError({
      code: '403',
      message: `Forbidden access by user`,
      error: new Error(`Forbidden: user attempted to access page ${to.path}`),
    })
    return next({ name: 'forbidden' })
  }

  await until(auth0.loading as Ref<boolean>).toBe(false)

  if (
    authRole &&
    authRole.includes('PracticeManager') &&
    !auth0.getCurrentPracticeId()
  ) {
    silentError({
      code: '403',
      message: `Forbidden access by user without practice id`,
      error: new Error(
        `Forbidden: user attempted to access page ${to.path} without a practice id`
      ),
    })

    return next({ name: 'forbidden' })
  }

  return next()
}
