import {
  initialize as ldClientInitialize,
  LDClient,
} from 'launchdarkly-js-client-sdk'
import { LDFlagSet } from 'launchdarkly-js-sdk-common'

import { Auth0UserWithAudience } from '@/domain/auth0'
import { VetUser } from '@/domain/pio/VetUser'
import { useFeatureFlags } from '@/store/featureFlags'

let instance: LDClient

export const useLaunchDarkly = () => {
  const store = useFeatureFlags()

  const initialise = async () => {
    const user = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'user',
    }

    const vet_practice = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'vet_practice',
    }

    const main_vet_practice = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'main_vet_practice',
    }

    instance = ldClientInitialize(
      import.meta.env.VITE_LAUNCH_DARKLY_ID,
      { kind: 'multi', user, vet_practice, main_vet_practice },
      {
        streaming: import.meta.env.VITE_LAUNCH_DARKLY_STREAMING === 'true',
      }
    )

    await instance.waitUntilReady()

    const rawFlags: Record<string, any> = instance.allFlags()
    store.setFeatureFlags(rawFlags)

    instance.on('change', (changes) => {
      handleFlagChanges(changes)
    })

    return instance
  }

  // TODO once admin users are removed we can make this whole props object optional
  // but each property within required. At the moment we can have a user who has no
  // practice because they are an admin
  const identify = async ({
    currentAuth0User,
    currentVetUser,
    currentPractice,
    mainVetPractice,
  }: {
    currentAuth0User?: Auth0UserWithAudience
    currentVetUser?: VetUser
    currentPractice?: string
    mainVetPractice?: string
  }) => {
    const user = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'user',
    }

    const vet_practice = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'vet_practice',
    }

    const main_vet_practice = {
      anonymous: true,
      key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
      kind: 'main_vet_practice',
    }

    // TODO: (REB-1573) Clean this up as part of the legacy vet user migration code
    if (currentAuth0User) {
      user.key = currentAuth0User.sub
      user.anonymous = false
    }

    if (currentVetUser) {
      user.key = currentVetUser.id
      user.anonymous = false
    }

    if (currentPractice) {
      vet_practice.key = currentPractice
      vet_practice.anonymous = false
    }

    if (mainVetPractice) {
      main_vet_practice.key = mainVetPractice
      main_vet_practice.anonymous = false
    }

    const updatedFlags = await instance.identify({
      kind: 'multi',
      user,
      vet_practice,
      main_vet_practice,
    })
    store.setFeatureFlags(updatedFlags)
  }

  const handleFlagChanges = (changes: LDFlagSet) => {
    const flattened: Record<string, any> = {}
    for (const key in changes) {
      flattened[key] = changes[key].current
    }
    store.setFeatureFlags(flattened)
  }

  const isReady = async () => {
    await instance.waitUntilReady()
  }

  if (!instance) {
    initialise()
  }

  return { initialise, identify, isReady, instance }
}
