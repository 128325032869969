// This mock of 'auth0/auth0-vue' is used for component tests
import type { Auth0VueClient } from '@auth0/auth0-vue'
import { jwtDecode } from 'jwt-decode'
import { readonly, ref } from 'vue'
import type { Plugin, App } from 'vue'

import { Auth0UserWithAudience } from '@/domain/auth0'

import { AuthTypes, Roles, Auth0Roles } from './types'

const isLoading = ref(true)
const isAuthenticated = ref(false)
const user = ref()
const error = ref(null)
const idToken = ref('')
const accessToken = ref('')
const idTokenClaims = ref({
  __raw: '12345',
  name: 'Fake user',
  email: 'fake@email.biz',
  exp: Date.now() / 1000 + 86400,
})

const auth0Instance: Partial<Auth0VueClient> = {
  isAuthenticated: readonly(isAuthenticated),
  isLoading: readonly(isLoading),
  user: readonly(user),
  error: readonly(error),
  async getAccessTokenSilently(): Promise<any> {
    return isAuthenticated.value ? accessToken.value : ''
  },
  async logout(): Promise<void> {
    isAuthenticated.value = false
    return
  },
  idTokenClaims: readonly(idTokenClaims) as any,
}

export const createAuth0 = (): Plugin => {
  const init = async (): Promise<void> => {
    idToken.value = window.sessionStorage.getItem('mock-id-token') ?? ''
    accessToken.value = window.sessionStorage.getItem('mock-access-token') ?? ''
    user.value = jwtDecode(idToken.value)
    isAuthenticated.value = true
    isLoading.value = false
  }

  // Initialise asynchronously to mimic how the original one works
  init()

  return {
    install(app: App): void {
      app.config.globalProperties.$auth0 = auth0Instance as Auth0VueClient
    },
    ...auth0Instance,
  }
}

export const useAuth0 = (): Partial<Auth0VueClient> => auth0Instance

export interface AccessTokenPayload {
  access_token: string
  refresh_token: string
  scope: string
  expires_in: number
  token_type: string
  audience: string | undefined
  oauthTokenScope: string
  client_id: string | undefined
}

const getAuth0Role = (authType: AuthTypes, role: Roles): Auth0Roles => {
  if (authType === 'practice_manager') {
    return 'PracticeManager'
  } else if (authType === 'bbm_admin' && role !== 'SysAdmin') {
    return 'Handler'
  }
  return 'SysAdmin'
}

export const getAccessTokenPayload = (): AccessTokenPayload => {
  return {
    access_token:
      'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InNwRlV4MVlRNTdPUGRfT3hrMFFPVSJ9.eyJodHRwczovL3d3dy50ZXN0dmV0cy54eXovcm9sZSI6IlByYWN0aWNlTWFuYWdlciIsImh0dHBzOi8vd3d3LnRlc3R2ZXRzLnh5ei9jb21wYW55X2lkcyI6WyIyODUxODg1MDAwIl0sImh0dHBzOi8vd3d3LnRlc3R2ZXRzLnh5ei92ZXRfcHJhY3RpY2VfaWRzIjpbInByYV82NjZxWVJDZVhQQTJpR3d4MnhrTEVFIl0sImh0dHBzOi8vd3d3LnRlc3R2ZXRzLnh5ei9lbWFpbCI6ImJibXRlc3RwcmFjdGljZW1hbmFnZXJAYmJtLmNvbSIsImlzcyI6Imh0dHBzOi8vdGVzdHZldHMuZXUuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDY0YzNlYTVmMzNlOTc2NWE1M2U0NjI3YyIsImF1ZCI6WyJodHRwczovL3d3dy50ZXN0dmV0cy54eXovIiwiaHR0cHM6Ly90ZXN0dmV0cy5ldS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzI4MzgzMzgwLCJleHAiOjE3MjgzODQyODAsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgb2ZmbGluZV9hY2Nlc3MiLCJhenAiOiJ0NnNacHdXNjk1d0p5SWV0MEN6SUZnNlZuQTMxVTNRTiIsInBlcm1pc3Npb25zIjpbImNsYWltczpyZXN0cmljdGVkX3JlYWQiLCJjbGFpbXM6cmVzdHJpY3RlZF93cml0ZSIsInBvbGljaWVzOnJlc3RyaWN0ZWRfcmVhZCIsInZldHM6cmVzdHJpY3RlZF9yZWFkIl19.veU7ye-FlzufJgV3bf_0jT9DwPFLxBoveqOUm9whD7DYoxOrRe8vSjkdKX6UGkYTqEHDYcJUZSeJIcD3j-8TyzuigDXIu7NM7zYyIFLHMKtvtroVl0WyuAoAMj3Tg5LYHlk3o69sPpwraIbChV4y-JJbF5x70KRlHuT2Rd-KQ-8gOu5GFgdGmvJRkEWRs1uwKbjSwKcAz5pZp-hZWaBt1PDQg1tOs3ejcbEBKCmINHp4elmLeOj4inOxbTk_oHbEPHsOLcM4aYOsXPnvSlsvPYF9MgP-vybhZtnaTVogKqAQDf3DMJZxkdlEr5_ONyH3TxCpSqMaI35rpw6EcOxO3A',
    refresh_token:
      'v1.Mj8I2iofaya6-HXl_7k6DZXNRs83G0A3Jg3ERS4aisVRHY3TRhpYaDZGLBm0jZmNfQt6CCx8bIrij2Lqswiz4Ko',
    scope: 'openid profile email offline_access',
    expires_in: 86400,
    token_type: 'Bearer',
    audience: process.env.VITE_AUTH0_AUDIENCE,
    oauthTokenScope: 'openid profile email offline_access',
    client_id: process.env.VITE_AUTH0_CLIENT_ID,
  }
}

export const getIdTokenPayload = (
  authType: AuthTypes,
  role: Roles = null,
  forUser = false
): Auth0UserWithAudience => {
  const auth0Role = getAuth0Role(authType, role)
  const auth0Sub = 'auth0|75c3ea5f33e9765a53e4627c'
  const email = 'BBMTestPracticeManager@BBM.com'
  const givenName = 'Auto'
  const familyName = 'User'
  const companyId = '2851885000'
  const vetPracticeId = 'pra_666qYRCeXPA2iGwx2xkLEE'
  const vetUserId = 'vet_user_BuSuvrkZZbAxdsAtNubYhd'
  const picture =
    'https://secure.gravatar.com/avatar/f36fc87cccb026c891bcf975edb8727a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbb.png'

  return {
    'https://www.testvets.xyz/role': auth0Role,
    ...(auth0Role === 'PracticeManager' && {
      'https://www.testvets.xyz/company_ids': [companyId],
      'https://www.testvets.xyz/vet_practice_ids': [vetPracticeId],
      'https://www.testvets.xyz/vet_user_id': vetUserId,
    }),
    'https://www.testvets.xyz/email': email,
    given_name: givenName,
    family_name: familyName,
    nickname: `${givenName} ${familyName}`,
    name: `${givenName} ${familyName}`,
    picture,
    updated_at: '2023-09-06T16:15:22.863Z',
    email,
    email_verified: true,
    sub: auth0Sub,
    ...(!forUser && {
      iss: `https://${process.env.VITE_AUTH0_DOMAIN}/`,
      aud: process.env.VITE_AUTH0_CLIENT_ID,
      iat: Date.now(),
      exp: Date.now() + 86400,
      sid: '3VEoZgrl8bbIpWSww2a_NjLTt8Hns37i',
      nonce: 'M1NFaHJUWFR2dVlzT2pOaTJ2aGxGMFlFVG45NXdEWTE4SVducDYyQXd6RA==',
    }),
  }
}
