import { defineStore } from 'pinia'

import { LAUNCH_DARKLY_FLAG_FALLBACK } from '@/config/launchDarklyDefaults'

const ldEnvironment: 'test' | 'production' =
  import.meta.env.VITE_LAUNCH_DARKLY_ENV_DEFAULTS || 'production'

const appDefaults = LAUNCH_DARKLY_FLAG_FALLBACK[ldEnvironment]

export const useFeatureFlags = defineStore('featureFlags', {
  state: () =>
    ({
      featureFlags: appDefaults,
    }) as Record<string, any>,
  actions: {
    setFeatureFlags(featureFlags: any) {
      this.featureFlags = { ...this.featureFlags, ...featureFlags }
    },
  },
  getters: {
    getFeatureFlags: (state) => state.featureFlags,
    getFeatureFlagByName: (state) => (flag: string) => state.featureFlags[flag],
  },
})
