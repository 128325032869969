import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { reactive, toRefs, computed } from 'vue'

import {
  CoverageBalance,
  PolicyCoverages,
} from '@/domain/application/application'
import { Claim } from '@/domain/pio/Claim'
import {
  Cover,
  PetV2,
  PolicyV2,
  Customer,
  PolicyLimitsV2CoverageLimit,
  PolicyLimitsSubCoverage,
  PolicyLimitsCoverage,
} from '@/domain/pio/Policy'
import { getExistingClaims } from '@/lib/claim'
import { findPolicies, getPolicyDetails } from '@/lib/policy'
import { getPolicyV2 } from '@/services/pio/policyService'

type SetPolicyDetailsPayload = {
  policyId?: string
  policyRef?: string
  petUuid: string
  startDate?: string
  endDate?: string
}

export const usePolicyStore = defineStore('policy', () => {
  // ----- State

  const state = reactive({
    selectedPolicy: <PolicyV2 | undefined>undefined,
    selectedPet: <PetV2 | undefined>undefined,
    customer: <Customer | undefined>undefined,
    cover: <Cover | undefined>undefined,
    limits: <PolicyLimitsV2CoverageLimit | undefined>undefined,
    existingClaims: <Claim[]>[],
  })

  // ----- Internal methods

  const getCoverage = (coverageNames: string[]): PolicyLimitsCoverage[] => {
    if (!state.limits?.coverages) {
      return []
    }

    return state.limits?.coverages.filter((coverage) =>
      coverageNames.includes(coverage.coverage)
    )
  }

  const getVetFeesCoverages = () => {
    const vetFeesCoverage = getCoverage(['vet_fees', 'vet-fees'])[0]
    if (vetFeesCoverage) {
      return vetFeesCoverage
    }

    return null
  }

  const getCoverageBalance = (
    coverage: PolicyLimitsCoverage
  ): CoverageBalance => {
    return {
      claimed: coverage.amount_spent / 100,
      pending: coverage.amount_in_progress / 100,
      total: coverage.policy_limit / 100,
      remaining: (coverage.policy_limit - coverage.amount_spent) / 100,
    }
  }

  const getSubCoverageBalance = (
    subCoverage: PolicyLimitsSubCoverage
  ): CoverageBalance => {
    return {
      claimed: subCoverage.amount_spent / 100,
      pending: subCoverage.amount_in_progress / 100,
      total: subCoverage.limit_amount ? subCoverage.limit_amount / 100 : 0,
      remaining: subCoverage.limit_amount
        ? (subCoverage.limit_amount - subCoverage.amount_spent) / 100
        : 0,
    }
  }

  const getSubCoveragesBalances = (
    coverage: PolicyLimitsCoverage,
    subcoverages: PolicyLimitsSubCoverage['name'][]
  ) => {
    const subCoveragesToReturn: {
      balance: CoverageBalance
      subCoverage: PolicyLimitsSubCoverage
    }[] = []

    subcoverages.forEach((subcoverageName) => {
      const subCoverage = coverage.subcoverages.find(
        (subcoverage) => subcoverage.name === subcoverageName
      )
      if (subCoverage) {
        const subcoverageBalance = getSubCoverageBalance(subCoverage)

        if (subcoverageBalance.remaining > 0) {
          subCoveragesToReturn.push({
            balance: subcoverageBalance,
            subCoverage: subCoverage,
          })
        }
      }
    })

    return subCoveragesToReturn
  }

  // ----- Getters

  const coverages = computed(() => {
    const allCoverages: PolicyCoverages[] = []
    const vetFeesCoverage = getVetFeesCoverages()
    if (vetFeesCoverage) {
      const vetFeesCoverageStateObject = {
        balance: getCoverageBalance(vetFeesCoverage),
        coverage: vetFeesCoverage,
        name: 'vet_fees', // use the SGP name for ease and future proofing. Will also aid ease of selection in the UI.
        subCoverages: getSubCoveragesBalances(vetFeesCoverage, [
          'complementary_treatment',
          'prescribed_food',
        ]),
      }

      allCoverages.push(vetFeesCoverageStateObject)
    }

    return allCoverages
  })

  // ----- Actions

  const selectPolicy = (policy?: PolicyV2) => {
    state.selectedPolicy = policy
  }

  const setPolicyDetails = async ({
    policyId,
    policyRef,
    petUuid,
    startDate,
    endDate,
  }: SetPolicyDetailsPayload) => {
    if (policyRef) {
      const { items: policies } = await findPolicies({
        field: policyRef,
        type: 'policyNumber',
      })

      if (policies.length === 0) {
        throw new Error(`No policies found for ref ${policyRef}`)
      }

      policies.find((policy) => {
        const pet = policy.pets.find((pet) => pet.uuid === petUuid)
        if (pet) {
          policyId = pet.policy_id
        }
      })

      if (!policyId) {
        throw new Error(`Policy id not found in returned pets for ${policyRef}`)
      }
    }

    //No need to call if policy is provided already
    if (!state.selectedPolicy) {
      if (!policyId) {
        throw new Error('Policy id not provided')
      }
      if (!startDate) {
        throw new Error('Start date not provided')
      }

      state.selectedPolicy = await getPolicyV2(policyId, {
        policy_at_date: dayjs(startDate).format('YYYY-MM-DD'),
      })
    }

    const { cover, customer, limits, pet } = await getPolicyDetails(
      state.selectedPolicy,
      petUuid,
      startDate,
      endDate
    )
    state.selectedPet = pet
    state.cover = cover
    state.customer = customer
    state.limits = limits
    state.existingClaims = []
  }

  const getExistingClaimsForSelectedPet = async () => {
    if (!state.selectedPet) {
      throw new Error('No policy selected')
    }

    state.existingClaims = await getExistingClaims({
      policyId: state.selectedPet.policy_id,
      insuredEntityId: state.selectedPet.uuid,
    })

    return state.existingClaims
  }

  const reset = () => {
    state.cover = undefined
    state.customer = undefined
    state.limits = undefined
    state.selectedPet = undefined
    state.selectedPolicy = undefined
    state.existingClaims = []
  }

  return {
    ...toRefs(state),
    coverages,
    selectPolicy,
    setPolicyDetails,
    getExistingClaimsForSelectedPet,
    reset,
  }
})
