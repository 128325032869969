import { Auth0VueClient } from '@auth0/auth0-vue'
import { until } from '@vueuse/core'
import { Auth } from 'aws-amplify'
import { jwtDecode } from 'jwt-decode'
import { ref, computed, unref, Ref, ComputedRef } from 'vue'

import { useError, ErrorArgs } from '@/composables/useError'
import { useLaunchDarkly } from '@/composables/useLaunchDarkly'
import { AvailableRoles } from '@/domain/auth0'
import { VetUser } from '@/domain/pio/VetUser'
import { HubUser } from '@/domain/vetPortal/HubUser'
import {
  heapIdentify,
  heapTrack,
  heapAddUserProperties,
} from '@/helpers/tracking'
import { useAuth0 as useAuth0Vue } from '@/lib/auth0/auth0-proxy'
import {
  isCheckUserAuthEnabled,
  isVetUserMigrationEnabled,
} from '@/lib/opsToggle'
import { updateCompany } from '@/services/companiesService'
import { searchCompanies } from '@/services/companiesService'
import { getHubUsersByAuth0Sub } from '@/services/hubUsersService'
import customerIO from '@/services/lambda-services/customerIO'
import { getSubBranches } from '@/services/pio/vetPracticeService'
import { getVetUser } from '@/services/pio/vetUserService'
import { clearAllStores } from '@/store'
import { useAuth } from '@/store/auth'

interface useAuth0Args {
  /**
   * Overwrite the current auth0 instance if it exists
   */
  overwriteInstance?: boolean
}

export interface useAuth0Instance extends Auth0VueClient {
  setup: Promise<void>
  auth0Loading: Ref<boolean, boolean>
  loading: ComputedRef<boolean>
  hubUser: Ref<HubUser | null>
  vetUser: Ref<VetUser | null>
  requiresAuth: Ref<boolean>
  logout: () => Promise<void>
  isExpired: () => boolean
  getUserRole: () => AvailableRoles
  getUserCompanyIds: () => string[]
  getCurrentPracticeId: () => string | undefined
  checkUserAuth: () => void
}

let instance: useAuth0Instance

export const useAuth0 = ({ overwriteInstance }: useAuth0Args = {}) => {
  if (instance && !overwriteInstance) return instance

  // Silent errors will become fatal errors after Auth0 migration is complete
  const { silentError, fatalError } = useError()
  const requiresAuth = ref(false)
  const error = ref(null)
  const hubUser = ref<HubUser | null>(null)
  const vetUser = ref<VetUser | null>(null)
  const auth0 = useAuth0Vue()
  const auth = useAuth()
  const { isReady } = useLaunchDarkly()

  const amplifyLoginLoading = ref(true)
  const practicesLoading = ref(true)

  const loading = computed(() => {
    return (
      unref(auth0.isLoading) ||
      unref(amplifyLoginLoading) ||
      unref(practicesLoading)
    )
  })

  const logout = () => {
    localStorage.clear()
    // Clear all Pinia stores
    clearAllStores()

    Auth.signOut()
    return auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const logoutOnError = (error: ErrorArgs) => {
    silentError(error)
    logout()
  }

  const getHubUser = async () => {
    try {
      if (!unref(auth0.user)?.sub) {
        throw new Error('Auth0 user sub is missing')
      }
      const { items } = await getHubUsersByAuth0Sub(
        unref(auth0.user)?.sub || ''
      )
      return items[0]
    } catch (error: any) {
      logoutOnError({
        message: 'Unable to fetch hub user by auth0 sub',
        code: 'E16009',
        error,
        extra: {
          data: {
            auth0Sub: unref(auth0.user)?.sub || 'missing',
          },
        },
      })
    }
  }

  const getUserVetPracticeIds = () => {
    const audience = import.meta.env.VITE_AUTH0_AUDIENCE
    return auth0.user.value?.[`${audience}vet_practice_ids`] || []
  }

  const isLoggedIntoAmplify = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      return true
    } catch {
      return false
    }
  }

  const amplifySignIn = async () => {
    let token = ''
    let claims
    let expires_at
    const domain = import.meta.env.VITE_AUTH0_DOMAIN || ''

    try {
      token = await auth0.getAccessTokenSilently()
    } catch (error: any) {
      // We don't mind if they need to log in again
      // sometimes the token is expired or similar
      if (error.message === 'Login required') {
        logout()
      } else {
        logoutOnError({
          message: 'Unable to get access token',
          code: 'E16005',
          error,
        })
      }
    }

    try {
      claims = unref(auth0.idTokenClaims)

      if (!claims) {
        throw new Error('Auth0 idTokenClaims is missing')
      }

      expires_at = (claims.exp || 0) * 1000

      const amplifyUser = await isLoggedIntoAmplify()

      if (!amplifyUser) {
        await Auth.federatedSignIn(
          domain,
          {
            token,
            expires_at,
          },
          {
            name: claims.name || '',
            email: claims.email,
          }
        )
      }
    } catch (error: any) {
      logoutOnError({
        message: 'Unable to complete amplify federated login',
        code: 'E16010',
        error,
        extra: {
          data: {
            hasToken: !!token,
            expires_at: expires_at || 0,
            domain,
            hasUser: !!claims?.name,
            hasEmail: !!claims?.email,
          },
        },
      })
    }
    amplifyLoginLoading.value = false
  }

  // This method is supporting the old user store for Vet users, the HubUser table
  const setupVetUser = async () => {
    try {
      const fetchedHubUser = await getHubUser()
      if (fetchedHubUser) {
        hubUser.value = fetchedHubUser

        const { verified_email: email, id: user_id } = unref(hubUser) || {}

        if (
          unref(hubUser)?.user_type?.includes('practice_manager') &&
          email &&
          user_id
        ) {
          customerIO.trackCustomerPasswordSet(email, user_id)
          try {
            const practiceIds = getUserVetPracticeIds()
            const {
              items: [company],
            } = await searchCompanies({
              vetPracticeListId: practiceIds,
              limit: 1,
            })

            if (company.status === 'INVITED') {
              try {
                const registeredDate = new Date()
                await updateCompany({
                  id: company.id,
                  status: 'REGISTERED',
                  registered_date: registeredDate.toISOString(),
                })
                await customerIO.trackCustomerCompanyRegistered(email, user_id)
              } catch (updateCompanyError: any) {
                silentError({
                  message: `Unable to update company status`,
                  code: 'E16008',
                  error: updateCompanyError,
                })
              }
            }
          } catch (getCompanyError: any) {
            fatalError({
              message: `Unable to get companies for user`,
              code: 'E16007',
              error: getCompanyError,
            })
          }
        } else {
          throw new Error('Hub user is missing')
        }
      } else {
        throw new Error('Auth0 user is missing')
      }
    } catch (error: any) {
      logoutOnError({
        message: 'Unable to complete federated login',
        code: 'E16004',
        error,
      })
    }
  }

  // This method is supporting the new user store for Vet users, which is in PIO.
  const setupUser = async () => {
    try {
      const fetchedUser = await getVetUser()
      if (fetchedUser) {
        vetUser.value = fetchedUser

        const { email, id: user_id } = unref(fetchedUser) || {}

        if (email && user_id) {
          customerIO.trackCustomerPasswordSet(email, user_id)

          try {
            const practiceIds = getUserVetPracticeIds()

            const {
              items: [company],
            } = await searchCompanies({
              vetPracticeListId: practiceIds,
              limit: 1,
            })

            if (company.status === 'INVITED') {
              try {
                const registeredDate = new Date()
                await updateCompany({
                  id: company.id,
                  status: 'REGISTERED',
                  registered_date: registeredDate.toISOString(),
                })
                customerIO.trackCustomerCompanyRegistered(email, user_id)
              } catch (updateCompanyError: any) {
                silentError({
                  message: `Unable to update company status`,
                  code: 'E16008',
                  error: updateCompanyError,
                })
              }
            }
          } catch (getCompanyError: any) {
            fatalError({
              message: `Unable to get companies for user`,
              code: 'E16007',
              error: getCompanyError,
            })
          }
        } else {
          throw new Error('Vet user is missing')
        }
      } else {
        throw new Error('Auth0 user is missing')
      }
    } catch (error: any) {
      logoutOnError({
        message: 'Unable to complete federated login',
        code: 'E16004',
        error,
      })
    }
  }

  const getSubBranchIds = async (id: string) => {
    const { items: subBranches } = await getSubBranches(id)

    if (!subBranches.length) {
      return [id]
    }

    return [id, ...subBranches.map((subBranch) => subBranch.id)]
  }

  // Get practice ids including sub branches
  const getAllUserVetPracticeIds = async (ids: string[]) => {
    const allIds = await Promise.all(
      ids.map(async (id: string) => await getSubBranchIds(id))
    )

    return allIds.flat()
  }

  const setUpPractices = async () => {
    // Have to set main branch ID(s) as practice first to prevent
    // 'No practice id found for current user' error in API call for getSubBranches
    const mainIds = getUserVetPracticeIds()
    await auth.setPractices(mainIds)

    const subBranchIds = await getAllUserVetPracticeIds(mainIds)
    await auth.setPractices(subBranchIds)

    practicesLoading.value = false
  }

  const isExpired = (): boolean => {
    //TODO: check if token exists first in seperate function
    const exp = unref(auth0.idTokenClaims)?.exp

    if (exp) {
      const now = Math.floor(Date.now() / 1000)
      const timeToExpire = exp - now

      return timeToExpire < 60
    }

    return false
  }

  const checkUserAuth = async () => {
    const isLoaded = !unref(auth0.isLoading) && !unref(amplifyLoginLoading)
    if (isCheckUserAuthEnabled() && unref(requiresAuth) && isLoaded) {
      let token
      try {
        token = await auth0.getAccessTokenSilently()
      } catch (error) {
        heapTrack({
          location: 'CheckUserAuth',
          event: 'Vet user auth has expired',
        })
        logout()
        return
      }

      const domain = import.meta.env.VITE_AUTH0_AUDIENCE || ''

      const decodedToken: {
        [key: string]: string
      } = jwtDecode(token)

      if (decodedToken[`${domain}role`] === 'PracticeManager') {
        const authPractice = decodedToken[`${domain}vet_practice_ids`][0]

        if (!auth.practices.includes(authPractice)) {
          heapTrack({
            location: 'CheckUserAuth',
            event: 'Multi practice usage detected',
          })

          window.location.assign('/hub/')
          return
        }
      }
    }
  }

  const setup = async () => {
    await isReady()
    try {
      await until(auth0.isLoading).toBe(false)

      if (isExpired()) {
        await auth0.loginWithRedirect({
          appState: { target: window.location.pathname },
        })
      }

      const user = unref(auth0.user)

      if (unref(auth0.isAuthenticated) && user?.sub) {
        await amplifySignIn()

        const role = getUserRole()

        if (role === 'PracticeManager') {
          if (isVetUserMigrationEnabled()) {
            await setupUser().then(() => {
              setUpPractices()
            })
          } else {
            await setupVetUser().then(() => {
              setUpPractices()
            })
          }
          heapAddUserProperties({
            vetPracticeListId: auth.currentPracticeId ?? '',
            vetPracticeName: auth.currentPractice?.name ?? '',
          })
        } else {
          amplifyLoginLoading.value = false
          practicesLoading.value = false
        }
        heapIdentify(user.sub)
      } else {
        amplifyLoginLoading.value = false
        practicesLoading.value = false
      }
    } catch (e: any) {
      error.value = e
      silentError({
        message: `Unable to renew session login after reload`,
        code: 'E16003',
        error: e,
      })
    }
  }

  const getUserRole = (): AvailableRoles => {
    const audience = import.meta.env.VITE_AUTH0_AUDIENCE
    return unref(auth0.user)?.[`${audience}role`]
  }

  instance = {
    ...auth0,
    setup: setup(),
    auth0Loading: auth0.isLoading,
    requiresAuth,
    loading,
    hubUser,
    vetUser,
    logout,
    isExpired,
    getUserRole,
    getUserCompanyIds() {
      const audience = import.meta.env.VITE_AUTH0_AUDIENCE
      return unref(auth0.user)?.[`${audience}company_ids`]
    },
    getCurrentPracticeId() {
      return auth.currentPracticeId
    },
    checkUserAuth,
  }

  return instance
}
