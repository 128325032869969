import cryptoJS from 'crypto-js'

export const encrypt = (val: string): string => {
  return cryptoJS.AES.encrypt(val, 'Secret Passphrase').toString()
}

export const decrypt = (val?: string): string => {
  return val
    ? cryptoJS.AES.decrypt(val, 'Secret Passphrase').toString(cryptoJS.enc.Utf8)
    : '-'
}

export default { encrypt, decrypt }
