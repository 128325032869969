import type { VetUser } from '@/domain/pio/VetUser'
import { API } from '@/lib/api'

export const getVetUser = async (): Promise<VetUser> => {
  return await new API().get('GET_VET_USER')
}

export const patchVetUser = async (
  body: Pick<
    VetUser,
    'first_name' | 'last_name' | 'job_title' | 'phone_no' | 'email'
  >
): Promise<VetUser> => {
  return await new API().patch('PATCH_VET_USER', {
    body,
  })
}

export const triggerPasswordChange = async (): Promise<string> => {
  return await new API().post('VET_USER_TRIGGER_PASSWORD_CHANGE')
}

export default { getVetUser, patchVetUser, triggerPasswordChange }
