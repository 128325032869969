export const LAUNCH_DARKLY_FLAG_FALLBACK = {
  production: {
    auto_create_missing_pio_practice: false, // TODO (REB-1260): Deprecated flag
    'better-policy-fetching': false, // TODO (REB-1260): Deprecated flag
    'check-user-auth': true,
    'claim-status-detailed': true, // TODO (REB-1260): Deprecated flag
    claims_consolidation: false, // TODO (REB-1260): Deprecated flag
    global_submitted_claims: true, // TODO (REB-1260): Deprecated flag
    'inner-limits-form-inputs': true,
    'inner-limits-summary-breakdown': false,
    main_branch_structure: false,
    'new-claim-form': true, // TODO (REB-1260): Deprecated flag
    'sgp-policy-sid': false, // TODO (REB-1260): Deprecated flag
    'show-save-for-later': false, // TODO (REB-1260): Deprecated flag
    show_document_requests: true, // TODO (REB-1260): Deprecated flag
    straight_through_processing: true, // TODO (REB-1260): Deprecated flag
    use_documents_api_at_fnol: true, // TODO (REB-1260): Deprecated flag
    'version-notification': true,
    'vet-user-migration': false,
    vet_fees_balance_cta: false, // TODO (REB-1260): Deprecated flag
  },
  test: {
    auto_create_missing_pio_practice: true,
    'better-policy-fetching': false,
    'check-user-auth': true,
    'claim-status-detailed': true,
    claims_consolidation: false,
    global_submitted_claims: true,
    'inner-limits-form-inputs': true,
    'inner-limits-summary-breakdown': true,
    main_branch_structure: false,
    'new-claim-form': true,
    'sgp-policy-sid': false,
    'show-save-for-later': false,
    show_document_requests: true,
    straight_through_processing: true,
    use_documents_api_at_fnol: true,
    'version-notification': true,
    'vet-user-migration': true,
    vet_fees_balance_cta: false,
  },
}
